<script>
import Paragraph from "@/components/text/paragraph.vue";
import MapComponent from "@/components/effects/map.vue";
import {markers} from "@/assets/js/markers";

export default {
  name: "part-block",
  components: {MapComponent, Paragraph},
  props: {
    part: {
      type: Object,
      default: null,
    },
    indexPart: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      mapKey: 0,
      countries: [],
      selectedRegions: [],
      optionsMap: {
        showTooltip: false,
        selectedRegions: null,
        regionStyle: {
          initial: {
            fill: '#3d3d3d',
            stroke: 'var(--dark)',
            strokeWidth: 2,
            strokeOpacity: 1,
            opacity: 0.5,
          },
          hover: false,
          selected: {
            fill: '#463829',
            opacity: 1,
          }
        },
        markerStyle: {
          initial: {
            r: 4,
          },
        },
        markerLabelStyle: {
          initial: {
            fill: 'var(--light-yellow)',
          }
        },
        labels: {
          markers: {
            render: (marker) => {
              return marker.shortTitle || marker.title;
            },
            offsets: (index) => {
              return markers[index].offsets
            }
          },
        },
      }
    }
  },
  methods: {
    forceRerender() {
      this.mapKey += 1;
    },
    getCountriesList() {
      this.countries = this.$refs.part.querySelectorAll('.map-country');
    },
    setSelectedRegions(item, index) {
      if (index > 0) this.countries[index - 1].classList.remove('show');
      const countriesItem = item.getAttribute('id')?.split('-');
      const description = item.getAttribute('data-tooltip')?.split(';');
      const single = countriesItem.includes('single');
      if (single) countriesItem.splice(countriesItem.indexOf('single'), 1);

      const marker = [];
      countriesItem.forEach((item, index) => {
        const mark = markers().find(area => area.name === item);
        mark.description = description?.length > 1 ? description[index] : description[0];
        mark.single = single;
        marker.push(mark);
      })

      this.$refs.map.map?.removeMarkers();
      this.$refs.map.map?.addMarkers(marker);
      this.$refs.map.map?.setSelectedRegions(countriesItem);
      this.selectedRegions = this.$refs.map.map?.getSelectedRegions();
      item.classList.add('show');
      // this.forceRerender();
    },
    showCountryOnMap() {
      this.getCountriesList();
      const heightPage = document.documentElement.clientHeight;
      let oldScrollPosition = 0;

      document.addEventListener('scroll', () => {
        oldScrollPosition = window.scrollY;

        this.countries.forEach((area, index) => {
          const topPositionArea = area.getBoundingClientRect().top;

          if (topPositionArea > 0 && topPositionArea <= heightPage / 2) this.setSelectedRegions(area, index)
        })
      })
    },
    preloadImages(imageUrls) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = imageUrls
        img.onload = resolve
        img.onerror = reject
      })
    }
  },
  async created() {
    if (this.part.bg) {
      await this.preloadImages(this.part.bg);
      this.img = this.part.bg;
    }
  },
  mounted() {
    this.showCountryOnMap();
    window.addEventListener('resize', this.forceRerender)
  }
}
</script>

<template>
  <div class="part" :style="{'--pic': `url(${part.bg})`}" ref="part">
    <div class="container">
      <div class="part-cover" :id="part.id">
        <div class="title-container">
          <h2 class="title-texture" v-html="part.title"></h2>
        </div>
        <template v-if="part.photoDescription">
          <div class="divider"></div>
          <a class="part-description" :href="part.link" target="_blank" v-html="part.photoDescription"
             v-if="part.link"></a>
          <div class="part-description" v-html="part.photoDescription" v-else></div>
        </template>

      </div>
      <!--      Part Description-->
      <div class="content-wrapper">
        <div class="map-wrapper">
          <map-component :options="optionsMap" ref="map" :selectedRegions="selectedRegions"
                         :key="mapKey"></map-component>
        </div>
        <div class="text-wrapper">
          <template v-if="part.description">
            <paragraph :paragraph="p" :indexPart="indexPart" v-for="(p, index) in part.description"
                       :key="`${indexPart}_description_${index}`"></paragraph>
          </template>

          <template v-if="part.child && part.child.length > 0" v-for="(chapter, indexChapter) in part.child">
            <div class="divider" v-if="indexChapter !== 0"></div>
            <h3 :key="`${indexPart}_chapter_${indexChapter}`" class="title" v-html="chapter.title"></h3>

            <template v-if="chapter.description">
              <paragraph :paragraph="p" :indexPart="indexPart" v-for="(p, indexChild) in chapter.description"
                         :key="`${indexPart}_${indexChapter}_child_${indexChild}`"></paragraph>
            </template>
            <template v-if="chapter.child && chapter.child.length > 0" v-for="(section, indexSection) in chapter.child">
              <h4 :key="`${indexPart}_${indexChapter}_section_${indexSection}`" v-html="section.title"></h4>
              <paragraph :paragraph="p" :indexPart="indexPart" v-for="(p, indexChild) in section.description"
                         :key="`${indexPart}_${indexChapter}_${indexSection}_child_${indexChild}`"></paragraph>
            </template>
          </template>

        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/sizes";

.map-wrapper {
  grid-column-start: 13;
  grid-column-end: 17;
  grid-row-start: 1;
  grid-row-end: 2;
  height: 100%;
  z-index: 2;
  padding-left: 2rem;

  @media ($tablet2) {
    padding-left: 0;
    margin-left: -0.1rem;
    z-index: 2;
  }

  @media ($tablet1) {
    z-index: 0;
  }
}

::v-deep {
  .map {
    position: sticky;
    top: 1rem;
    z-index: -2;
    max-height: calc(100vh - 2rem);
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    padding-bottom: 1rem;
    mask-image: linear-gradient(180deg, transparent, black 4%, black 96%, transparent);

    &::-webkit-scrollbar {
      display: none;
    }

    @media ($tablet2) {
      top: 0rem;
    }
  }

  .jvm-container {
    width: 100%;
    height: 20rem;

    @media ($tablet2) {
      width: 90%;
      height: 20rem;
    }

    @media ($tablet1) {
      width: 90%;
      height: 10rem;
    }
  }
}

.part-cover {
  height: 100vh;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 10rem;

  @media ($tablet2) {
    min-height: 40vh;
    height: auto;
    margin-bottom: 2rem;
  }

  .title-container {
    flex: 1 1;
    display: flex;
    align-items: center;
  }

  .divider {
    margin: 0 0 1rem;
    opacity: 0.5;
    background: var(--light-yellow);

    @media ($tablet2) {
      margin: 2rem 0 0;
    }
  }
}

.part-description {
  position: relative;
  font-family: "Advent Pro", sans-serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 1px;
  font-size: 0.8rem;
  margin: 1rem 0;
  flex: 0 0;
  color: var(--light-yellow);
  text-decoration: none;

  @media ($tablet2) {
    margin-bottom: 0;
  }
}

a.part-description {
  &::before {
    content: '';
    width: 2rem;
    aspect-ratio: 1/1;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("~@/assets/icons/out.svg");
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.3;
    transition: opacity .3s, width 0.3s;

    @media ($tablet2) {
      display: none;
    }
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
}

.title-texture {
  background-image: url("/img/grunge_black.webp");
  background-clip: text;
  background-size: 50%;
  background-position: 0 0;
  color: transparent;
  background-color: white;
}

.content-wrapper {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-auto-rows: 1fr;
}

.text-wrapper {
  pointer-events: none;
  grid-column-start: 1;
  grid-column-end: 17;
  grid-row-start: 1;
  grid-row-end: 2;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-auto-rows: auto;
  position: sticky;

  @media ($tablet2) {
    grid-template-columns: repeat(17, 1fr);
    //display: flex;
    //flex-direction: column;
  }

  h3 {
    grid-column-start: 1;
    grid-column-end: 5;
    margin-bottom: 1rem;
    margin-right: 2rem;
    height: 0;

    @media ($tablet2) {
      height: auto;
      grid-column-start: 1;
      grid-column-end: 13;
    }
  }

  h4 {
    grid-column-start: 5;
    grid-column-end: 13;

    @media ($tablet2) {
      grid-column-start: 1;
      grid-column-end: 13;
    }
  }
}

.paragraph {
  grid-column-start: 5;
  grid-column-end: 13;

  &:last-child {
    p:last-child {
      margin: 0;
    }
  }

  @media ($tablet2) {
    grid-column-start: 1;
    grid-column-end: 13;
  }
}

.part {
  position: relative;
  padding: 10rem 0;
  gap: 20rem;
  z-index: 1;

  @media ($tablet2) {
    padding: 4rem 0;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: var(--pic);
    background-size: 100%, 40%;
    background-repeat: no-repeat, repeat;
    background-position: top center;
    z-index: -1;

    @media ($tablet2) {
      opacity: 0.5;
      mask-image: linear-gradient(0deg, transparent 80%, black);
    }

    @media ($tablet1) {
      background-size: 180%, 100%;
      opacity: 1;
      mask-image: none;
    }
  }
}

</style>
