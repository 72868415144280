<script>
import SparksBg from "@/components/effects/sparksBg.vue";
import SequenceSlide from "@/components/sequence/sequenceSlide.vue";
import {text} from "@/assets/js/data";
import TitleLongRead from "@/components/sequence/titleLongread.vue";

export default {
  name: "sequenceBlock",
  computed: {
    text() {
      return text
    }
  },
  components: {TitleLongRead, SequenceSlide, SparksBg},
  data() {
    return {
      slide1: false,
      slide2: false,
      title: false,
      showTitleSlide: false,
      showPhoto: false,
      showVideo: false,
      time: 8,
    }
  },
  methods: {
    nextSlide(oldSlide, newSlide) {
      this[newSlide] = true;

      setTimeout(() => {
        this[oldSlide] = false;
        this.showPhoto = true
        this.showTitleSlide = true;
      }, this.time * 1000 / 1.5)
    },
    startVideo(oldSlide) {
      this.showVideo = true;

      setTimeout(() => {
        this[oldSlide] = false;
      }, this.time * 1000 / 1.5)
    }
  },
  mounted() {
    setTimeout(() => {
      this.slide1 = true;
    }, 2000)
  }
}
</script>

<template>
  <div class="sequence-wrapper">
    <sequence-slide :sequence="text.opening1" v-if="slide1" :time="time" @hidden-slide="nextSlide('slide1', 'slide2')" :key="1"></sequence-slide>
    <sequence-slide :sequence="text.opening2" v-if="slide2" :time="time" :key="2" @hidden-slide="startVideo('slide2')"></sequence-slide>
    <title-long-read v-show="showTitleSlide" :show-photo="showPhoto" :show-video="showVideo"></title-long-read>
    <sparks-bg :time="time"></sparks-bg>
  </div>
</template>

<style scoped lang="scss">
.sequence-wrapper {
  position: relative;
  height: 100vh;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}
</style>
