<template>
  <div v-if="text" class="slide" :style="{'--timeAnimation': `${time}s`}" ref="text">
    <span class="slide__text" v-html="text" ></span>
    <scroll-down-icon :time="time"></scroll-down-icon>
  </div>
</template>

<script>
import Icon from "@/components/Icon/Icon.vue";
import ScrollDownIcon from "@/components/scrollDownIcon.vue";

export default {
  name: 'slide-credits',
  components: {ScrollDownIcon, Icon},
  props: {
    text: {
      type: String,
      default: null,
    },
    isTragedy: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Number,
      default: 5,
    }
  },
  methods: {
    hiddenSlide() {
      this.$refs.text.classList.add('hidden');
      this.$emit('hidden-slide');
    }
  },
  mounted() {
    setTimeout(() => {
      window.onscroll = () => {
        this.hiddenSlide();
      }
    }, this.time * 1000)
  }
}

</script>

<style scoped lang="scss">
@import "../assets/style/utils/sizes";

.slide {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: calc(1rem + 2vw);
  font-variation-settings: "wght" 700, "wdth" 900;
  line-height: calc((1rem + 2vw) * 1.4);
  letter-spacing: calc(0.02rem + 0.05vw);
  text-align: center;

  &__text {
    mask-image: linear-gradient(-45deg, transparent 20%, black 100%);
    mask-position: 150% 0%;
    mask-size: 300% 110%;
    mask-repeat: no-repeat;
    animation: show-text var(--timeAnimation) forwards;
    opacity: 1;
    transform: scale(.8);
    filter: blur(30px);
    padding: 0 calc(1rem + 15vw);

    @media ($tablet1) {
      padding: 0 calc(1rem + 5vw);
    }
  }

  &.hidden {
    animation: hidden-text 1.5s forwards;
  }
}

@keyframes show-text {
  40% {
    filter: blur(0);
  }

  50% {
    filter: blur(0);
    transform: scale(1);
  }

  100% {
    mask-position: 0 0;
    opacity: 1;
    transform: scale(1);
    filter: blur(0);
  }
}

@keyframes hidden-text {
  0% {
    opacity: 1;
    filter: blur(0);
  }

  20% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(.98);
    filter: blur(20px);
    color: var(--dark);
  }
}


::v-deep .accent-text {
  color: red !important;
  display: block;
}

</style>
