<script>
import VueParticle from "vue-particlejs";

export default {
  name: "sparks-bg",
  components: {VueParticle},
  props: {
    time: {
      type: Number,
      default: 5,
    }
  },
  data() {
    return {
      particleConfig: {
        "particles": {
          "number": {"value": 32, "density": {"enable": true, "value_area": 3472.256473489519}},
          "color": {"value": ["#fde3a9", "#ffb600", "#ff9100"]},
          "shape": {"type": "circle", "stroke": {"width": 0, "color": "#000000"}, "polygon": {"nb_sides": 5}},
          "opacity": {"value": 1, "random": false, "anim": {"enable": true, "speed": 0.5594405594405595, "opacity_min": 0.1, "sync": false}},
          "size": {"value": 2, "random": true, "anim": {"enable": false, "speed": 40, "size_min": 32.76723276723277, "sync": true}},
          "line_linked": {"enable": false, "distance": 804.9321824907522, "color": "#ffffff", "opacity": 0.4, "width": 1},
          "move": {
            "enable": true,
            "speed": 1,
            "direction": "top-right",
            "random": true,
            "straight": false,
            "out_mode": "out",
            "bounce": false,
            "attract": {"enable": false, "rotateX": 3235.5117139334156, "rotateY": 2446.3625154130705}
          }
        },
        "interactivity": {
          "detect_on": "window",
          "events": {"onhover": {"enable": false, "mode": "repulse"}, "onclick": {"enable": false, "mode": "push"}, "resize": true},
          "modes": {
            "grab": {"distance": 400, "line_linked": {"opacity": 1}},
            "bubble": {"distance": 400, "size": 40, "duration": 2, "opacity": 8, "speed": 3},
            "repulse": {"distance": 200, "duration": 0.4},
            "push": {"particles_nb": 4},
            "remove": {"particles_nb": 2}
          }
        },
        "retina_detect": true
      },
      particleConfig2: {
        "particles": {
          "number": {"value": 2, "density": {"enable": true, "value_area": 3472.256473489519}},
          "color": {"value": "#ffb600"},
          "shape": {"type": "circle", "stroke": {"width": 0, "color": "#000000"}, "polygon": {"nb_sides": 5}},
          "opacity": {"value": 1, "random": false, "anim": {"enable": true, "speed": 0.5594405594405595, "opacity_min": .2, "sync": false}},
          "size": {"value": 2, "random": true, "anim": {"enable": false, "speed": 40, "size_min": 32.76723276723277, "sync": true}},
          "line_linked": {"enable": false, "distance": 804.9321824907522, "color": "#ffffff", "opacity": 0.4, "width": 1},
          "move": {
            "enable": true,
            "speed": 6,
            "direction": "top",
            "random": true,
            "straight": false,
            "out_mode": "out",
            "bounce": false,
            "attract": {"enable": false, "rotateX": 3235.5117139334156, "rotateY": 2446.3625154130705}
          }
        },
        "interactivity": {
          "detect_on": "window",
          "events": {"onhover": {"enable": false, "mode": "repulse"}, "onclick": {"enable": false, "mode": "push"}, "resize": true},
          "modes": {
            "grab": {"distance": 400, "line_linked": {"opacity": 1}},
            "bubble": {"distance": 400, "size": 40, "duration": 2, "opacity": 8, "speed": 3},
            "repulse": {"distance": 200, "duration": 0.4},
            "push": {"particles_nb": 4},
            "remove": {"particles_nb": 2}
          }
        },
        "retina_detect": true
      },
    }
  }
}
</script>

<template>
  <div class="show-spark" :style="{'--timeAnimation': `${time}s`}">
    <div id='particle' class="particle"></div>
    <div id='particle2' class="particle"></div>
    <VueParticle domId='particle' :config="particleConfig"/>
    <VueParticle domId='particle2' :config="particleConfig2"/>
  </div>
</template>

<style scoped lang="scss">
.particle {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.show-spark {
  opacity: 0;
  animation: show-spark 3s  forwards;
}

@keyframes show-spark {
  100% {
    opacity: 1;
  }
}
</style>
