export const markersDesktop = [
    {name: "MA", title: "Марокко", coords: [28.8206, -9.8025], offsets: [-2, 0], description: " ", single: true},
    {
        name: "CG",
        title: "Республика Конго",
        shortTitle: "Конго",
        coords: [-1.1206, 15.8025],
        offsets: [-2, 0],
        description: " ",
        single: true
    },
    {
        name: "CD",
        title: "Демократическая республика Конго",
        shortTitle: "ДРК",
        coords: [-6.8206, 23.8025],
        offsets: [-42, 0],
        description: " ",
        single: true
    },
    {name: "ET", title: "Эфиопия", coords: [9, 40.8025], offsets: [-74, 0], description: " ", single: true},
    {
        name: "GQ",
        title: 'Республика Экваториальная Гвинея',
        shortTitle: "Экв. Гвинея",
        coords: [1.6, 10.3],
        offsets: [-94, 0],
        description: " ",
        single: true
    },
    {name: "SN", title: "Сенегал", coords: [14, -14], offsets: [-2, 0], description: " ", single: true},
    {name: "GW", title: "Республика Гвинея-Бисау",  shortTitle: "Гвинея-Бисау", coords: [12.3, -15], offsets: [-2, 0], description: " ", single: true},
    {name: "CV", title: "Кабо-Верде", coords: [16, -24], offsets: [-2, 0], description: " ", single: true},
    {name: "GH", title: "Гана ", coords: [8, -2], offsets: [-2, 0], description: " ", single: true},
    {name: "AO", title: "Ангола", coords: [-12, 17], offsets: [-62, 0], description: " ", single: true},
    {
        name: "TZ",
        title: "Танзания",
        coords: [-6, 40],
        offsets: [-76, 0],
        description: " ",
        single: true
    },
    {
        name: "MZ",
        title: "Республика Мозамбик",
        shortTitle: "Мозамбик",
        coords: [-19, 34],
        offsets: [-82, 0],
        description: " ",
        single: true
    },
    {name: "BF", title: "Буркина-Фасо", coords: [12, -2], offsets: [-4, 0], description: " ", single: true},
    {name: "DJ", title: "Джибути", coords: [12, 42.5], offsets: [-74, 0], description: " ", single: true},
    {name: "BI", title: "Бурунди", coords: [-4, 30], offsets: [-4, 0], description: " ", single: true},
    {name: "BJ", title: "Бенин", coords: [10, 2], offsets: [-12, -8], description: " ", single: true},
    {
        name: "ZA",
        title: "Южно-Африканская Республика",
        shortTitle: "ЮАР",
        coords: [-29, 25],
        offsets: [-46, 0],
        description: " ",
        single: true
    },
    {name: "BW", title: "Ботсвана", coords: [-23, 23], offsets: [-64, 10], description: " ", single: true},

    {name: "DZ", title: "Алжир", coords: [27, 3], offsets: [-58, 0], description: " ", single: true},
    {name: "RW", title: "Руанда", coords: [-2, 30], offsets: [-62, 0], description: " ", single: true},
    {name: "NA", title: "Намибия", coords: [-23, 16], offsets: [-72, 0], description: " ", single: true},
    {name: "NE", title: "Нигер", coords: [17, 8], offsets: [-2, 0], description: " ", single: true},
    {name: "NG", title: "Нигерия", coords: [10, 7], offsets: [-2, 0], description: " ", single: true},
    {name: "TN", title: "Тунис", coords: [34, 9], offsets: [-2, 0], description: " ", single: true},
    {name: "LR", title: "Либерия", coords: [7, -10], offsets: [-4, 0], description: " ", single: true},
    {name: "LS", title: "Лесото", coords: [-29.5, 28], offsets: [-4, 0], description: " ", single: true},
    {name: "ZW", title: "Зимбабве", coords: [-19, 29], offsets: [-80, 0], description: " ", single: true},
    {name: "CI", title: "Кот-д Ивуар", coords: [7, -6], offsets: [-2, 0], description: " ", single: true},
    {name: "EH", title: "Западная Сахара", coords: [23, -13], offsets: [-4, -1], description: " ", single: true},
    {name: "CM", title: "Камерун", coords: [5, 12], offsets: [-2, 0], description: " ", single: true},
    {name: "EG", title: "Египет", coords: [26, 30], offsets: [-2, 0], description: " ", single: true},
    {name: "SL", title: "Сьерра-Леоне", coords: [8.5, -12], offsets: [-2, 0], description: " ", single: true},
    {
        name: "CF",
        title: "Центральноафриканская Республика",
        shortTitle: "ЦАР",
        coords: [7, 20],
        offsets: [-2, 0],
        description: " ",
        single: true
    },
    {name: "GA", title: "Габон", coords: [-1, 11], offsets: [-58, 0], description: " ", single: true},
    {name: "GN", title: "Гвинея", coords: [10, -10], offsets: [-4, -1], description: " ", single: true},
    {name: "GM", title: "Гамбия", coords: [14, -14], offsets: [-2, 0], description: " ", single: true},
    {name: "XS", title: "Сомалиленд", coords: [10, 46], offsets: [-60, -10], description: " ", single: true},
    {name: "SZ", title: "Свазиленд", coords: [-26, 31.5], offsets: [-4, -1], description: " ", single: true},
    {name: "MG", title: "Мадагаскар", coords: [-18, 47], offsets: [-64, -10], description: " ", single: true},
    {name: "KE", title: "Кения", coords: [1, 37], offsets: [-58, 0], description: " ", single: true},
    {name: "SS", title: "Южный Судан", coords: [7, 30], offsets: [-110, 0], description: " ", single: true},
    {name: "ML", title: "Мали", coords: [17, -4], offsets: [-10, -10], description: " ", single: true},
    {
        name: "KM",
        title: "Коморские острова",
        shortTitle: "Коморы",
        coords: [-12, 42],
        offsets: [-18, -10],
        description: " ",
        single: true
    },
    {name: "ST", title: "Сан-Томе и Принсипи", coords: [2.5, 8], offsets: [-4, -1], description: " ", single: true},
    {name: "MW", title: "Малави", coords: [-13, 33.5], offsets: [-10, -10], description: " ", single: true},
    {name: "SO", title: "Сомали", coords: [2, 43], offsets: [-66, 0], description: " ", single: true},
    {name: "MR", title: "Мавритания", coords: [20, -10], offsets: [-2, 0], description: " ", single: true},
    {name: "UG", title: "Уганда", coords: [1.5, 32], offsets: [-2, 0], description: " ", single: true},
    {name: "SD", title: "Судан", coords: [16, 30], offsets: [-2, 0], description: " ", single: true},
    {name: "TG", title: "Того", coords: [8, 1.2], offsets: [-2, 0], description: " ", single: true},
    {name: "TD", title: "Чад", coords: [13, 18], offsets: [-2, 0], description: " ", single: true},
    {name: "ER", title: "Эритрея", coords: [16, 38], offsets: [-70, 0], description: " ", single: true},
    {name: "LY", title: "Ливия", coords: [27, 18], offsets: [-2, 0], description: " ", single: true},
    {name: "ZM", title: "Замбия", coords: [-14, 27], offsets: [-64, 0], description: " ", single: true},
]

export const markersMobile = [
    {name: "MA", title: "Марокко", coords: [28.8206, -9.8025], offsets: [-6, -4], description: " ", single: true},
    {
        name: "CG",
        title: "Республика Конго",
        shortTitle: "Конго",
        coords: [-1.1206, 15.8025],
        offsets: [-6, -1],
        description: " ",
        single: true
    },
    {
        name: "CD",
        title: "Демократическая республика Конго",
        shortTitle: "ДРК",
        coords: [-6.8206, 23.8025],
        offsets: [-6, -1],
        description: " ",
        single: true
    },
    {name: "ET", title: "Эфиопия", coords: [9, 40.8025], offsets: [-42, -1], description: " ", single: true},
    {
        name: "GQ",
        title: 'Республика Экваториальная Гвинея',
        shortTitle: "Экв. Гвинея",
        coords: [1.6, 10.3],
        offsets: [-10, 5],
        description: " ",
        single: true
    },
    {name: "SN", title: "Сенегал", coords: [14, -14], offsets: [-10, 5], description: " ", single: true},
    {name: "GW", title: "Республика Гвинея-Бисау",  shortTitle: "Гвинея-Бисау", coords: [12.3, -15], offsets: [-10, 5], description: " ", single: true},
    {name: "CV", title: "Кабо-Верде", coords: [16, -24], offsets: [-10, -5], description: " ", single: true},
    {name: "GH", title: "Гана ", coords: [8, -2], offsets: [-6, -1], description: " ", single: true},
    {name: "AO", title: "Ангола", coords: [-12, 17], offsets: [-35, -1], description: " ", single: true},
    {
        name: "TZ",
        title: "Танзания",
        coords: [-6, 40],
        offsets: [-44, -1],
        description: " ",
        single: true
    },
    {
        name: "MZ",
        title: "Республика Мозамбик",
        shortTitle: "Мозамбик",
        coords: [-19, 34],
        offsets: [-44, -1],
        description: " ",
        single: true
    },
    {name: "BF", title: "Буркина-Фасо", coords: [12, -2], offsets: [-4, -1], description: " ", single: true},
    {name: "DJ", title: "Джибути", coords: [12, 42.5], offsets: [-40, -1], description: " ", single: true},
    {name: "BI", title: "Бурунди", coords: [-4, 30], offsets: [-38, 4], description: " ", single: true},
    {name: "BJ", title: "Бенин", coords: [10, 2], offsets: [-12, -8], description: " ", single: true},
    {
        name: "ZA",
        title: "Южно-Африканская Республика",
        shortTitle: "ЮАР",
        coords: [-29, 25],
        offsets: [-26, -1],
        description: " ",
        single: true
    },
    {name: "BW", title: "Ботсвана", coords: [-23, 23], offsets: [-64, 10], description: " ", single: true},

    {name: "DZ", title: "Алжир", coords: [27, 3], offsets: [-10, -6], description: " ", single: true},
    {name: "RW", title: "Руанда", coords: [-2, 30], offsets: [-10, -6], description: " ", single: true},
    {name: "NA", title: "Намибия", coords: [-23, 16], offsets: [-42, -1], description: " ", single: true},
    {name: "NE", title: "Нигер", coords: [17, 8], offsets: [-6, -1], description: " ", single: true},
    {name: "NG", title: "Нигерия", coords: [10, 7], offsets: [-6, -1], description: " ", single: true},
    {name: "TN", title: "Тунис", coords: [34, 9], offsets: [-6, -1], description: " ", single: true},
    {name: "LR", title: "Либерия", coords: [7, -10], offsets: [-6, -1], description: " ", single: true},
    {name: "LS", title: "Лесото", coords: [-29.5, 28], offsets: [-4, -1], description: " ", single: true},
    {name: "ZW", title: "Зимбабве", coords: [-19, 29], offsets: [-40, 4], description: " ", single: true},
    {name: "CI", title: "Кот-д Ивуар", coords: [7, -6], offsets: [-6, -1], description: " ", single: true},
    {name: "EH", title: "Западная Сахара", coords: [23, -13], offsets: [-4, -1], description: " ", single: true},
    {name: "CM", title: "Камерун", coords: [5, 12], offsets: [-6, -1], description: " ", single: true},
    {name: "EG", title: "Египет", coords: [26, 30], offsets: [-34, -1], description: " ", single: true},
    {name: "SL", title: "Сьерра-Леоне", coords: [8.5, -12], offsets: [-6, -1], description: " ", single: true},
    {
        name: "CF",
        title: "Центральноафриканская Республика",
        shortTitle: "ЦАР",
        coords: [7, 20],
        offsets: [-6, -1],
        description: " ",
        single: true
    },
    {name: "GA", title: "Габон", coords: [-1, 11], offsets: [-32, -1], description: " ", single: true},
    {name: "GN", title: "Гвинея", coords: [10, -10], offsets: [-6, -1], description: " ", single: true},
    {name: "GM", title: "Гамбия", coords: [14, -14], offsets: [-6, -1], description: " ", single: true},
    {name: "XS", title: "Сомалиленд", coords: [10, 46], offsets: [-60, -10], description: " ", single: true},
    {name: "SZ", title: "Свазиленд", coords: [-26, 31.5], offsets: [-4, -1], description: " ", single: true},
    {name: "MG", title: "Мадагаскар", coords: [-18, 47], offsets: [-64, -10], description: " ", single: true},
    {name: "KE", title: "Кения", coords: [1, 37], offsets: [-32, -1], description: " ", single: true},
    {name: "SS", title: "Южный Судан", coords: [7, 30], offsets: [-60, -1], description: " ", single: true},
    {name: "ML", title: "Мали", coords: [17, -4], offsets: [-10, -5], description: " ", single: true},
    {
        name: "KM",
        title: "Коморские острова",
        shortTitle: "Коморы",
        coords: [-12, 42],
        offsets: [-18, -10],
        description: " ",
        single: true
    },
    {name: "ST", title: "Сан-Томе и Принсипи", coords: [2.5, 8], offsets: [-4, -1], description: " ", single: true},
    {name: "MW", title: "Малави", coords: [-13, 33.5], offsets: [-20, -6], description: " ", single: true},
    {name: "SO", title: "Сомали", coords: [2, 43], offsets: [-36, 0], description: " ", single: true},
    {name: "MR", title: "Мавритания", coords: [20, -10], offsets: [-6, -1], description: " ", single: true},
    {name: "UG", title: "Уганда", coords: [1.5, 32], offsets: [-36, -1], description: " ", single: true},
    {name: "SD", title: "Судан", coords: [16, 30], offsets: [-6, -1], description: " ", single: true},
    {name: "TG", title: "Того", coords: [8, 1.2], offsets: [-6, -6], description: " ", single: true},
    {name: "TD", title: "Чад", coords: [13, 18], offsets: [-6, -1], description: " ", single: true},
    {name: "ER", title: "Эритрея", coords: [16, 38], offsets: [-40, -1], description: " ", single: true},
    {name: "LY", title: "Ливия", coords: [27, 18], offsets: [-6, -1], description: " ", single: true},
    {name: "ZM", title: "Замбия", coords: [-14, 27], offsets: [-36, -1], description: " ", single: true},
]

export const markers = () => {
    const width = window.innerWidth;
    return width > 1200 ? markersDesktop : markersMobile;
}



