export const regions = [
    "MA",
    "CG",
    "CD",
    "ET",
    "GQ",
    "SN",
    "GW",
    "CV",
    "GH",
    "AO",
    "TZ",
    "MZ",
    "BF",
    "DJ",
    "BI",
    "BJ",
    "ZA",
    "BW",
    "DZ",
    "RW",
    "NA",
    "NE",
    "NG",
    "TN",
    "LR",
    "LS",
    "ZW",
    "CI",
    "EH",
    "CM",
    "EG",
    "SL",
    "CF",
    "GA",
    "GN",
    "GM",
    "XS",
    "SZ",
    "MG",
    "KE",
    "SS",
    "ML",
    "KM",
    "ST",
    "MW",
    "SO",
    "MR",
    "UG",
    "SD",

    "TG",
    "TD",
    "ER",
    "LY",
    "ZM",
]
