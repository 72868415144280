<script>
import p5 from 'p5';
export default {
  name: "videoP5",
  methods: {
    initP5 () {
      new p5(this.drawCanvas, this.$refs.video);
    },
    drawCanvas(p5) {
      p5.setup = _ => {
        p5.createCanvas(1000, 1000);
      }

      p5.draw = _ => {
        p5.background(135, 206, 235);
      }
    },

  },
  mounted() {
    this.initP5();
  }
}
</script>

<template>
  <div class="video-container" ref="video">
  </div>
</template>

<style scoped lang="scss">
  .video-container, canvas {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
</style>
