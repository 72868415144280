<script>
import Projects from "@/components/projects.vue";
import {text} from "@/assets/js/data";

export default {
  name: "sidebar",
  components: {Projects},
  data () {
    return {
      text: text,
    }
  }
}
</script>

<template>
  <div class="sidebar">
    <div class="chapters">
      <a class="chapter-item" v-for="(chapter, index) in text.parts" :key="index + chapter.title"
         @click="$emit('toggleSidebar');"
         :href="`#${chapter.id}`" :style="{'--bg': `url('${chapter.bg}')` }">
        <span class="chapter-index">{{ 'Часть ' + (index + 1) }}</span>
        <span class="chapter-title">
            {{ chapter.title }}
          </span>
      </a>
    </div>

    <projects></projects>
  </div>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/sizes";
@import "~@/assets/style/utils/typography";

.sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 100vw;
  width: 100%;
  z-index: 99;
  transform: translateY(-200vh);
  transition: transform 1s;
  box-sizing: border-box;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &.is-active {
    transform: translateX(0);
  }

  &::before {
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: -8rem;
    background: linear-gradient(0deg, rgba(#181818, 0), rgba(#181818, 0.8) 10%, rgba(#181818, 1) 40%);
    pointer-events: none;
  }

  @media ($tablet2) {
    padding: 1rem;
    background: linear-gradient(0deg, rgba(#181818, 0), rgba(#181818, 0.4) 5%, rgba(#181818, 1) 40%);
  }
}

.chapters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 2rem;
  //margin: 3rem;
  margin: 3rem 0;

  @media ($tablet2) {
    grid-template-columns: repeat(1, 1fr);
    gap: 0.4rem;
    margin: 2.3rem 0;
  }
}

.chapter {
  &-item {
    position: relative;
    border-bottom: 1px solid rgba(white, .2);
    color: var(--light-yellow);
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 2rem 1rem 0;
    transition: padding 1s, border 1s;

    &::before {
      content: '';
      z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-image: var(--bg);
      background-size: 200%;
      background-repeat: no-repeat;
      background-position: 10% 20%;
      opacity: 0;
      transition: background-position 10s, opacity 2s;
    }

    &:hover {
      padding: 1rem ;
      border-color: rgba(white, 0);

      .chapter-title {
        color: var(--light-yellow);
      }

      &::before {
        opacity: 1;
        background-position: 0 20%;

      }
    }

    @media ($tablet2) {

    }
  }

  &-index {
    font-size: calc(0.4rem + 0.5vw);
    margin-bottom: .2rem;
    color: var(--darkgray);
  }

  &-title {
    @include title;
    color: var(--light);
    font-size: calc(0.6rem + 0.5vw);
    line-height: calc(0.6rem + 0.5vw);
    transition: color 1s;
  }
}

@keyframes show-title {
  0% {
    opacity: 0;
    mask-position: 150% 0;
  }

  100% {
    opacity: 1;
    mask-position: 0 0;
  }
}
</style>
