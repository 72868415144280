<script>
export default {
  name: "slider-country",
  props: {
    countries: {
      type: Array,
      default: null,
    }
  },
  data() {
    return {
      indexActive: 0,
    }
  },
  methods: {
    goToSlide(index) {
      const slides = this.$refs.slider.querySelectorAll('.country-container');
      const coordsNew = slides[index].offsetLeft;
      const coordTo = this.indexActive < index ? coordsNew : (-1 * coordsNew);

      this.$refs.slider.scrollTo({left: coordTo, behavior: 'smooth'});
      this.indexActive = index;
    },
  },
}
</script>

<template>
  <div class="country-wrapper">
    <template v-if="!countries[0]?.single">
      <div class="country-slider" ref="slider">
        <div v-for="(country, index) in countries" :key="index" class="country-container">
          <div class="country-header__wrapper">
            <div class="country-header">
              <div class="country-flag"><img :src="`/img/flags/${country.name}.webp`" alt="" rel="preload"></div>
              <div class="country-title">{{ country.title }}</div>
            </div>
          </div>
          <div class="country-description" v-html="country.description"></div>
        </div>
      </div>
      <div class="pagination" v-if="countries.length > 1">
        <div class="circle" :class="{'active': index - 1 === indexActive}" v-for="(index) in countries.length"
             @click="goToSlide(index - 1)"></div>
      </div>
    </template>
    <template v-else>
      <div class="country-slider">
        <div class="country-container">
          <div class="country-header__wrapper">
            <div class="country-header" v-for="(country, index) in countries">
              <div class="country-flag"><img :src="`/img/flags/${country.name}.webp`" alt="" rel="preload"></div>
              <div class="country-title" :key="index">{{ country.title }}<template>{{ countries[index + 1] ? ',' : '' }}</template></div>
            </div>
          </div>
          <div class="country-description" v-html="countries[0]?.description"></div>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/sizes";
@import "~@/assets/style/utils/typography";

.country {
  &-wrapper {
    z-index: 3;
    position: relative;
    margin: -1rem 0 1.2rem;
    mask-image: linear-gradient(90deg, transparent, black 8%, black 92%, transparent);
    display: flex;
    flex-direction: column;

    @media ($tablet2) {
      margin: 0;
      z-index: 15;
      mask-image: none;
    }
  }

  &-header {
    display: flex;
    align-items: center;

    @media ($tablet2) {
      align-items: flex-start;
    }

    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 0.4rem;
      margin-bottom: .4rem;
    }
  }

  &-slider {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    overflow-x: auto;
    scroll-behavior: smooth;
    pointer-events: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media ($tablet2) {
      pointer-events: auto;
    }
  }

  &-container {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 0 1rem;
    box-sizing: border-box;

    @media ($tablet2) {
      padding: 0;
      //margin-top: -2rem;
    }
  }

  &-flag {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .35rem;
    width: 1.3rem;
    aspect-ratio: 6/3.2;
    padding: 0;

    @media ($tablet2) {
      width: 1rem;
      margin-top: 0.15rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: 0;
      padding: 0;
    }
  }

  &-title {
    flex: 1 1;
    @include title;
    font-size: 0.8rem;
    line-height: 1;
    margin-bottom: 0.1rem;
    hyphens: auto;
  }

  &-description {
    flex: 0 0 100%;
    font-family: "Advent Pro", sans-serif;
    font-weight: 400;
    font-style: italic;
    letter-spacing: 1px;
    font-size: 0.8rem;
    color: var(--darkgray);
    hyphens: auto;

    @media ($tablet2) {
      font-size: 0.7rem;
    }
  }
}

.pagination {
  display: flex;
  gap: .4rem;
  padding: 0 0 1rem;
  margin: 0 1rem;
  z-index: 3;
  order: -1;

  @media ($tablet2) {
    margin: 0 0.1rem 1rem;
    padding-top: 0;
    gap: .5rem;
    border-top: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.circle {
  flex: 0 0 0.4rem;
  aspect-ratio: 1/1;
  background: var(--darkgray);
  border-radius: 100px;
  cursor: pointer;

  @media ($tablet2) {
    flex: 0 0 0.5rem;
  }

  &.active {
    background: var(--light-yellow);
  }
}

</style>
