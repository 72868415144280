<script>

export default {
  name: "projects",
  data() {
    return {
      projects: [
        {
          link: 'https://doctrine.repost.press/',
          name: '82-й год гибридной войны <br>США против Мира',
          bg: '/img/projects/1.webp',
        },
        {
          link: 'https://cosmos.repost.press/',
          name: 'Новая космическая <br>гонка',
          bg: '/img/projects/2.webp',
        },
        {
          link: 'https://repost.press/',
          name: 'Репост. <br>Cетевое аналитическое СМИ',
          bg: '/img/projects/3.webp',
        },
      ]
    }
  }
}
</script>

<template>
  <div class="another-projects">
    <h4>Читайте также</h4>
    <a class="project_item" v-for="(item, index) in projects" :key="index" :href="item.link" target="_blank" :style="{'background-image': `url(${item.bg})`}" v-html="item.name">
    </a>
  </div>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/sizes";
@import "~@/assets/style/utils/typography";

.another-projects {
  z-index: 2;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  @media ($tablet2) {
    gap: 0.4rem;
    margin: 0;
  }

  h4 {
    flex: 0 0 100%;
    align-items: center;
  }
}
.project_item {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  flex: 1 1;
  padding: 2rem;
  background-position: top right;
  background-size: contain;
  background-repeat: no-repeat;
  text-decoration: none;
  color: var(--light-yellow);

  &:hover {
    &:before {
      background-position: 10%;
    }
  }

  @media (max-width: 1780px) {
    flex: 1 1 100%;

    &:before {
      background-size: 150%;
    }

    ::v-deep br {
      display: none;
    }
  }

  @media ($tablet2) {
    font-size: 0.8rem;
    padding: 1rem;
    box-sizing: border-box;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url('/img/blood_texture_2.webp');
    background-position: 0;
    z-index: -1;
    opacity: .3;
    transition: background-position 1s;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -2;
    background-color: var(--darken);
    transition: background-position 1s;
  }
}
</style>
