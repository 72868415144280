<script>
import MapComponent from "@/components/effects/map.vue";
import {regions} from "@/assets/js/regions";
import {markers} from "@/assets/js/markers";

export default {
  name: "testMap",
  components: {MapComponent},
  data() {
    return {
      options: {
        showTooltip: false,
        selectedRegions: regions,
        markers: this.markerRegion(),
        regionStyle: {
          initial: {
            fill: 'black',
            stroke: 'var(--dark)',
            strokeWidth: 1,
            strokeOpacity: 1,
            animation: true,
            animate: true,
          },
          selected: {fill: 'var(--light-yellow)', opacity: 0.2},
          hover: false
        },
        markerStyle: {
          initial: {
            r: 4,
            fill: 'var(--light-yellow)',
            stroke: '#463829',
            strokeWidth: 3,
            strokeOpacity: 1,
          },
        },
        markerLabelStyle: {
          initial: {
            fill: 'var(--light-yellow)',
            fontSize: 11,
          }
        },
        labels: {
          markers: {
            render: (marker, index) => {
              console.log(marker, index)
              return marker.shortTitle || marker.title;
            },
            offsets: (index) => {
              return markers[index].offsets
            }
          },
        },
      }
    }
  },
  methods: {
    markerRegion() {
      const areas = regions.map((area) => {
        return markers.find(country => area === country.name)
      })
      return areas;
    }
  }
}
</script>

<template>
  <div>
    <map-component :options="options"></map-component>
  </div>
</template>

<style scoped lang="scss">

div {
  width: 300px;
  height: 350px;
}

::v-deep {
  .map {
    width: 100%;
    height: 100%;
  }

  .jvm-container {
    width: 100%;
    height: 100%;
    padding: 3rem;
    box-sizing: border-box;
  }
}
</style>
