<script>
import {getDirectionScroll} from "@/assets/js/plugins";

export default {
  name: "to-top-btn",
  data() {
    return {
      isDirection: false,
    }
  },
  methods: {
    scroll() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    },
    getDirectionScroll() {
      let oldTopPosition = 0;
      window.addEventListener('scroll', () => {
        const res = getDirectionScroll(oldTopPosition)
        this.isDirection = res.result;
        oldTopPosition = res.topPosition;
      })
    }
  },
  mounted() {
    this.getDirectionScroll();
  }
}
</script>

<template>
  <a class="btn" @click="scroll()" :class="{'is-active': isDirection}"></a>
</template>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.btn {
  position: fixed;
  background: var(--yellow);
  right: 2rem;
  bottom: 2rem;
  height: 2rem;
  width: 2rem;
  z-index: 99;
  cursor: pointer;
  border-radius: 0.7rem;
  background-image: url("/img/grunge_black.webp");
  background-size: 720px;
  background-position: 20% 20%;
  transform: translate(50%, 250%) rotate(45deg) scale(0.8);
  transition: transform 1s, background-size 1s, border-radius 1s;

  &.is-active {
    transform: translate(50%, 50%) rotate(45deg) scale(0.8);
  }

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 2px;
    background: var(--dark);
    height: 55%;
    transform: translate(-50%, -50%) rotate(-45deg);
    border-radius: 2px;
  }

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0.4rem;
    height: 0.4rem;
    transform: translate(-80%, -80%);
    border: 2px solid var(--dark);
    border-right: none;
    border-bottom: none;
  }

  &:hover {
    background-size: 600px;
    border-radius: 1rem;
    transform: translate(50%, 50%) rotate(45deg) scale(1);
  }
}
</style>
