<script>
import MapComponent from "@/components/effects/map.vue";
import ScrollDownIcon from "@/components/scrollDownIcon.vue";
import Icon from "@/components/Icon/Icon.vue";

export default {
  name: "cover",
  components: {Icon, ScrollDownIcon, MapComponent},
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      isShowBtnScroll: false,
      optionsMap: {
        showTooltip: false,
        regionStyle: {
          initial: {
            fill: 'var(--yellow)',
            stroke: 'var(--yellow)',
            strokeWidth: 12,
            strokeOpacity: 1,
            animation: true,
            animate: true,
          },
          selected: false,
          hover: false
        }
      }
    }
  },
  watch: {
    isLoading (newVal, oldVal) {
      if(!newVal) setTimeout(() => {this.isShowBtnScroll = true;}, 1000)
    }
  },
  methods: {
    hiddenCover() {
      this.$refs.cover.classList.add('hidden');
      this.$refs.cover.removeEventListener('wheel', this.hiddenCover);
      this.$refs.cover.removeEventListener('touchmove', this.hiddenCover);
      this.$emit('hidden-cover');
    },
  },
  mounted() {
    setTimeout(() => {
      this.$refs.cover.addEventListener('wheel', this.hiddenCover);
      this.$refs.cover.addEventListener('touchmove', this.hiddenCover);
    }, 3000)
  }
}
</script>

<template>
  <div class="cover" ref="cover">
    <div class="map-container">
      <div class="cover-logo">
        <img src="/img/logo.svg" alt="">
      </div>
      <div class="cover-title">
        АФРИКА <span>И МИР</span>
      </div>
      <div class="cover-description">
        ОСНОВНЫЕ<br>
        ЭТАПЫ<br>
        РАЗВИТИЯ<br>
      </div>
      <map-component :options="optionsMap"></map-component>
    </div>

    <scroll-down-icon :time="5000" :is-show="isShowBtnScroll"></scroll-down-icon>
  </div>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/sizes";
//@import "animate.css/animate.css";

.map-container{
  opacity: 0;
  animation: 7s fadeIn forwards;
}

.cover {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &.hidden {
    animation: fadeOut 2s forwards;
  }

  &::before {
    pointer-events: none;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url("/img/grunge.webp");
    background-size: 40%;
    background-position: center;
    transform: rotate(15deg);
    opacity: 0;
    animation: 6s fadeIn 0.5s forwards;
    mask-image: radial-gradient(circle at center, black 10%, transparent 50%);

    @media ($tablet1) {
      background-size: 100%;
      mask-image: radial-gradient(circle at center, black 30%, transparent 50%);
    }
  }

  &-title {
    font-family: "Advent Pro", sans-serif;
    font-size: 570%;
    line-height: 92%;
    font-variation-settings: "wght" 900, "wdth" 900;

    background-image: url("/img/grunge.webp"), url("/img/grunge.webp");
    background-clip: text;
    background-size: 100%, 100%;
    background-position: center, 0 0 ;
    color: transparent;
    background-color: var(--dark);

    position: absolute;
    width: auto;
    top: 23%;
    margin-left: 5.5%;
    z-index: 2;
    pointer-events: none;

    span {
      display: block;
      margin-left: 10rem;
    }

    @media ($tablet2) {
      top: 15%;
      margin-left: 3%;
      font-size: 340%;
      line-height: 92%;

      span {
        margin-left: 6rem;
      }
    }
  }

  &-description {
    position: absolute;
    left: 58.5%;
    top: 46%;
    z-index: 2;
    color: var(--dark);
    font-weight: 600;
    font-size: 130%;
    line-height: 140%;

    @media ($tablet2) {
      left: 57.5%;
      top: 42%;
      font-size: 72%;
    }
  }

  &-logo {
    position: absolute;
    top: 0%;
    right: 5%;
    width: 46%;
    height: auto;
    z-index: 2;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media ($tablet2) {
      top: -11%;
      right: 12%;
      width: 40%;
    }
  }
}

.map-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44rem;
  height: 44rem;
  padding-top: 5rem;
  margin-left: -2rem;
  transform: scale(.5);
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url("/img/grunge_black.webp");
    background-size: 100%;
    background-position: center;
    opacity: 0;
    z-index: 1;
    pointer-events: none;
    animation: 7s fadeIn forwards;
  }

  @media ($tablet2) {
    padding-top: 0;
    width: 26rem;
    height: 24rem;
    transform: scale(.8);
  }
}

::v-deep {
  .map {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  }

  .jvm-container {
    width: 100%;
    height: 100%;
  }
}

</style>
