<script>

import {getDirectionScroll} from "@/assets/js/plugins";

export default {
  name: "header-component",
  components: {},
  data() {
    return {
      isDirection: false,
    }
  },
  methods: {
    getDirectionScroll() {
      let oldTopPosition = 0;
      window.addEventListener('scroll', () => {
        const res = getDirectionScroll(oldTopPosition)
        this.isDirection = res.result;
        oldTopPosition = res.topPosition;
      })
    },
    toggleSidebar () {

      this.$emit('toggleSidebar');
    }
  },
  mounted() {
    this.getDirectionScroll();
  }
}
</script>

<template>
  <header :class="{'is-show': isDirection}">
    <a class="header__logo" href="/">
      <img src="/img/logo.svg" alt="">
    </a>

    <div class="header__menu" @click="toggleSidebar()" ref="menu"></div>
  </header>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/sizes";
 header {
   position: fixed;
   display: flex;
   justify-content: space-between;
   right: 0;
   top: 0;
   left: 0;
   height: 6rem;
   z-index: 100;
   transform: translateY(-100%);
   transition: transform 1s;

   &::before {
     content: '';
     position: absolute;
     left: 0;
     right: 0;
     top: 0;
     bottom: 0;
     background: var(--dark);
     mask-image: linear-gradient(180deg, var(--dark) 0%, transparent);
     backdrop-filter: blur(10px);
   }

   @media ($tablet2) {
     &::before {
       mask-image: linear-gradient(180deg, var(--dark) 20%, transparent);
     }
   }

   &.is-show {
     transform: translateY(0);
   }
 }

 .header {
   &__logo {
     position: relative;
     height: 4rem;
     aspect-ratio: 3/1;
     transition: opacity 0.3s;

     @media ($tablet2) {
       aspect-ratio: 2/1;
       margin-left: 0.2rem;
     }

     &:hover {
       opacity: 0.7;
     }

     img {
       width: 100%;
       height: 100%;
       object-fit: contain;
     }
   }

   &__menu {
     --size: 2px;
     position: relative;
     height: 0.9rem;
     margin: 1.4rem;
     aspect-ratio: 2/1;
     border-top: var(--size) solid white;
     border-bottom: var(--size) solid white;
     cursor: pointer;
     transition: opacity 0.3s;

     &::before, &::after {
       content: '';
       position: absolute;
       height: var(--size);
       top: 50%;
       left: 0;
       right: 50%;
       transform: translateY(-50%);
       background: white;
     }

     @media ($tablet2) {
       margin: 1.6rem 1.3rem;
       height: 0.7rem;
       --size: 2px;
     }

     &:hover {
       opacity: .7;
       &:before {
         right: 0;
         transition: right 0.7s;
       }
     }

     &.is-active {
       border-color: transparent;

       &::before {
         right: 0;
         transform:  translateY(-50%) rotate(45deg);
       }

       &::after {
         right: 0;
         transform:  translateY(-50%) rotate(-45deg);
       }
     }
   }
 }
</style>
