<script>
import Icon from "@/components/Icon/Icon.vue";

export default  {
  name: 'scroll-down-icon',
  components: {Icon},
  props: {
    time: {
      type: Number,
      default: 5,
    },
    lengthSequence: {
      type: Number,
      default: 1,
    },
    isShow: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    timeAnimation () {
      let count = this.time;

      if(this.lengthSequence > 1) {
        for(let i = 0; i < this.lengthSequence - 1; i++) {
          let val = this.time - (this.time / 2);
          count += val;
        }
      }

      return count / 1.5;
    }
  },
}

</script>

<template>
 <div class="icon-container" :style="{'--timeAnimation': `${timeAnimation}s`}"  :class="{show: isShow}">
   <icon name="mouse" color="var(--light)" :size="32" class="icon animation"></icon>
 </div>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/sizes";

.icon {
  position: absolute;
  left: 50%;
  bottom: 2rem;
  transform:  translateY(-14px) translateX(-50%);

  @media ($tablet2) {
    width: 24px !important;
    height: 24px !important;
  }


  &.animation {
    animation: ease-in-out  mouse-jumping 1s alternate infinite;
  }
}

.icon-container {
  opacity: 0;
  transition: 2s opacity;

  &.show {
    opacity: 0.5;
  }
}

@keyframes mouse-jumping {
  100% {
    transform: translateY(0px) translateX(-50%);
  }
}
</style>
