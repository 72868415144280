<script>
import jsVectorMap from 'jsvectormap'
import './africa_mill'
import {markers} from "@/assets/js/markers";
import SliderCountry from "@/components/sliderCountry.vue";

export default {
  name: "map-component",
  components: {SliderCountry},
  props: {
    options: {
      type: Object,
      default: () => {
      },
    },
    isAnimation: {
      type: Boolean,
      default: false,
    },
    selectedRegions: {
      type: Array,
      default: null,
    }
  },
  data() {
    return {
      map: null,
      mapKey: 0,
    }
  },
  computed: {
    listMarkers() {
      let result = [];
      if (this.map && this.selectedRegions?.length > 0) {
        result = this.selectedRegions.map(area => {
          return markers().find(item => area === item.name)
        })
      }
      return result;
    }
  },
  methods: {
    initMap() {
      return new Promise((resolve, reject) => {
        this.map = new jsVectorMap({
          ...this.options,
          selector: this.$refs.map,
          map: 'africa_mill',
          zoomOnScroll: false,
          zoomButtons: false,
        });

        setTimeout(() => resolve("done!"), 1000)
      })
    },
    setRegions(index) {
      if (index >= 0) {
        const regs = this.map.getSelectedRegions();
        setTimeout(() => {
          this.map.setSelectedRegions([...regs, this.selectedRegions[index]]);
          this.setRegions(index - 1);
        }, 50)
      }
    },
    forceRerender() {
      this.mapKey += 1;
    },
  },
  async mounted() {
    await this.initMap();
    if (this.isAnimation) this.setRegions(this.selectedRegions?.length - 1);
  }
}
</script>

<template>
  <div class="map">
    <div ref="map"></div>
    <slider-country :countries="listMarkers" v-if="listMarkers" :key="mapKey"></slider-country>
  </div>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/sizes";
@import "~@/assets/style/utils/typography";


.jvm-container {
  position: relative;
  margin-bottom: -4rem;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url("/img/grunge_black.webp"), url("/img/grunge_black.webp");
    background-size: 100%, 60%;
    background-position: center, 0 0;
    opacity: 1;
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url("/img/grunge.webp");
    background-size: 130%;
    background-position: center;
    z-index: -1;
    opacity: .4;
  }

  @media ($tablet1) {
    margin-bottom: 0;
  }
}


::v-deep #jvm-markers-labels-group text {
  @media ($tablet2) {
    font-size: 6px;
  }
}

::v-deep #jvm-markers-group circle.jvm-marker.jvm-element {
  r: 4px;
  stroke-width: 3px;
  fill: var(--light-yellow);
  stroke: #463829;
  stroke-opacity: 1;

  @media ($tablet2) {
    r: 1.5px;
    stroke-width: 1px;
  }
}

::v-deep #jvm-markers-labels-group text.jvm-marker.jvm-element {
  //filter: drop-shadow(0 5px 6px black);
}

::v-deep #jvm-regions-group .jvm-region.jvm-element {
  //fill: url('/img/1/bg_part.webp');
}

</style>
