<script>

import MapComponent from "@/components/effects/map.vue";

export default {
  name: "paragraph",
  components: {MapComponent},
  props: {
    paragraph: {
      type: [String, Object],
      default: null,
    },
    indexPart: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      img: null,
    }
  },
  methods: {
    preloadImages(imageUrls) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = imageUrls
        img.onload = resolve
        img.onerror = reject
      })
    }
  },
  async created() {
    if (this.paragraph.img) {
      await this.preloadImages(this.paragraph.img);
      this.img = this.paragraph.img;
    }
  }
}
</script>

<template>
  <div class="paragraph animate__animated animate__fadeIn" v-if="typeof paragraph !== 'object'">
    <p v-html="paragraph" lang="ru"></p>
  </div>
  <div class="quote__wrapper" v-else-if="paragraph.sign">
    <a class="quote__author" :href="paragraph.link" target="_blank">
      <div class="quote__author-img">
        <img :src="`/img/${paragraph.person}`" alt="">
        <div class="quote__author-img-container">
          <img :src="`/img/${paragraph.bg}`" alt="">
        </div>
      </div>
      <span v-html="paragraph.author" class="quote__author-text"></span>
    </a>
    <div class="quote" v-html="paragraph.quote">
    </div>
    <div class="divider"></div>
    <div class="quote__sign" v-html="paragraph.sign"></div>
  </div>
  <div class="illustration__wrapper" v-else-if="paragraph.img" :class="{'small': paragraph.imgType === 'small'}">
    <div class="illustration-container">
      <img :src="this.img" alt="" @click="$emit('showModal')" v-if="paragraph.img" rel="preload">
    </div>
    <div class="illustration-description" v-html="paragraph.imgDescription">
    </div>
  </div>
  <div class="attention-description" v-else-if="paragraph.attention" v-html="paragraph.attention"> </div>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/sizes";

p {
  font-size: 1rem;
  line-height: 1.6rem;
  margin: 0 0 calc(0.2rem + 1vw);
  width: 100%;
  padding: 0;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  pointer-events: auto;

  @media ($tablet2) {
    margin-bottom: 1rem;
  }
}

.divider {
  margin: 1.2rem 0;
  grid-column-start: 5;
  grid-column-end: 13;

  @media ($tablet2) {
    grid-column-start: 1;
    grid-column-end: 13;
  }
}

.quote__wrapper {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-column-start: 1;
  grid-column-end: 17;
  z-index: 2;
  pointer-events: auto;

  @media ($tablet2) {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: -4rem;
      bottom: -4rem;
      left: -2rem;
      right: -3rem;
      z-index: -2;
      background: linear-gradient(0deg, transparent, var(--dark) 10%, var(--dark) 85%, transparent);
    }
  }
}

.quote__sign {
  font-family: "Advent Pro", sans-serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 1px;
  font-size: 0.8rem;
  margin: 0 0 1.2rem;
  color: var(--darkgray);
  grid-column-start: 5;
  grid-column-end: 13;

  @media ($tablet2) {
    margin-bottom: 0;
  }
}

.quote__author {
  font-family: "Advent Pro", sans-serif;
  font-weight: 400;
  height: 1px;
  font-size: 0.8rem;
  margin-top: -2rem;
  letter-spacing: 1px;
  cursor: pointer;
  text-decoration: none;
  grid-column-start: 1;
  grid-column-end: 4;

  &-text {
    display: block;
    color: var(--darkgray);
    transition: transform 0.3s;
    hyphens: auto;
    flex: 1 1;

    ::v-deep b {
      display: inline-block;
      color: var(--light-yellow);
      margin-bottom: 0.5rem;
    }
  }

  @media ($tablet2) {
    order: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: auto;
    height: auto;
    margin: 0;
  }

  ::v-deep .divider {
    margin: 0.8rem 0;
  }

  &:hover {
    .quote__author-text {
      transform: translateY(0.5rem);
    }

    .quote__author-img {
      transform: translateY(0rem);

      & > img {
        transform: translateX(-50%) scale(1.1);
      }
    }

    .quote__author-img-container {
      transform: translateY(-1rem) scale(0.9);
    }
  }

  &-img {
    position: relative;
    --size: 9rem;
    width: var(--size);
    margin: 1rem 0 1.5rem;
    transition: transform 0.3s;
    cursor: pointer;

    @media ($tablet2) {
      margin: 0;
      order: 1;
      --size: 7rem;
      flex: 0 0 var(--size);
    }

    & > img {
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 80%;
      z-index: 2;
      margin-left: -0rem;
      margin-bottom: -0.4rem;
      transform: translateX(-50%);
      transition: transform 0.3s;
      filter: drop-shadow(-10px 15px 10px var(--dark));
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      aspect-ratio: 1/1;
      mask-image: linear-gradient(0deg, black, transparent);
      transition: transform 0.3s;

      img {
        width: 90%;
        height: 90%;
        object-fit: cover;
      }
    }
  }
}

.quote {
  position: relative;
  grid-column-start: 5;
  grid-column-end: 13;
  font-family: "Advent Pro", sans-serif;
  font-size: calc(1rem + 0.5vw);
  line-height: calc((1rem + 0.5vw) * 1.4);
  letter-spacing: .05rem;
  font-variation-settings: "wght" 600;
  z-index: 1;
  color: var(--light-yellow);
  hyphens: auto;
  word-break: break-word;


  &:after {
    content: '';
    position: absolute;
    left: -30%;
    right: -30%;
    top: -20%;
    bottom: -10%;
    background: url("/img/grunge.webp");
    background-repeat: no-repeat;
    pointer-events: none;
    transform: rotate(-15deg);

    @media ($tablet2) {
      background-repeat: repeat;
      background-size: 50%;
    }
  }

  @media ($tablet2) {
    font-size: calc(1rem + 1vw);
    padding-bottom: 1.5rem;
    margin: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  &::before {
    content: '«';
    position: absolute;
    left: -2rem;
    top: 0.25rem;
    color: var(--light-yellow);
    font-size: 8rem;
    line-height: 0;
    height: 8rem;
    z-index: -1;
    opacity: .2;

    @media ($tablet2) {
      font-size: 6rem;
      left: -1rem;
    }
  }

  &__wrapper {
    margin: 0.6rem 0 1.5rem;

    @media ($tablet2) {
      display: flex;
      flex-direction: column-reverse;
      margin: 1rem 0 2rem;
    }

    .divider {

    }
  }
}

::v-deep .bad {
  background-image: url('/img/blood_texture.webp');
  background-size: 100%;
  background-position: center;
  //background-repeat: no-repeat;
}

.illustration {
  &__wrapper {
    grid-column-start: 5;
    grid-column-end: 13;
    //margin:  calc(1rem + 1vw) 0 calc(3rem + 1vw);
    margin: 1rem 0 0;
    display: grid;
    pointer-events: auto;
    grid-template-columns: repeat(12, 1fr);

    @media ($tablet2) {
      position: relative;
      display: flex;
      flex-direction: column;
      margin: calc(1.2rem + 1vw) 0;
      grid-column-start: 1;
      grid-column-end: 13;


    }

    @media ($tablet1) {
      grid-column-start: 1;
      grid-column-end: 18;

      &::before {
        content: '';
        position: absolute;
        top: -4rem;
        bottom: -4rem;
        left: -2rem;
        right: -3rem;
        z-index: -2;
        background: linear-gradient(0deg, transparent, var(--dark) 10%, var(--dark) 85%, transparent);
      }
    }

    &.small {
      height: 1px;
      grid-column-start: 1;
      grid-column-end: 5;
      display: flex;
      flex-direction: column;
      margin: 0;
      padding-top: 0.7rem;
      padding-right: 4rem;

      @media ($tablet2) {
        grid-column-start: 1;
        grid-column-end: 13;
        height: auto;
        padding: 1rem 0;
        margin: 0.6rem 0 1rem;
        align-items: center;
        flex-direction: row;
        gap: 1rem;
        border-bottom: 1px solid rgba(white, 0.1);
        border-top: 1px solid rgba(white, 0.1);

        &::before {
          display: none;
        }
      }

      .illustration-container {
        position: relative;
        z-index: 2;
        margin-bottom: 1rem;
        margin-right: 3rem;

        @media ($tablet2) {
          margin-right: 0;
          margin-bottom: 0;
          flex: 0 0 30%;
        }

        &:after {
          background-size: 100%;
        }
      }

      .illustration-description {
        margin: 0;
        padding-bottom: 0.8rem;
        border-bottom: none;

        @media ($tablet2) {
          padding: 0;
          margin: 0;
          height: auto;
        }

        ::v-deep b {
          display: inline-block;
          color: var(--light-yellow);
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  &-container {
    position: relative;
    grid-column-start: 1;
    grid-column-end: 13;
    margin: 0 0 1rem;
    padding: 0;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ($tablet2) {
      margin-bottom: 1rem;
    }

    &:after {
      content: '';
      position: absolute;
      left: -20%;
      right: -10%;
      top: -20%;
      bottom: -10%;
      background: url("/img/grunge.webp");
      background-size: 50%;
      pointer-events: none;
      transform: rotate(-15deg);

      @media ($tablet2) {
        background-repeat: repeat;
        background-size: 50%;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: 0;
      padding: 0;
    }
  }

  &-description {
    font-family: "Advent Pro", sans-serif;
    font-weight: 400;
    font-style: italic;
    letter-spacing: 1px;
    font-size: 0.8rem;
    line-height: 1rem;
    color: var(--darkgray);
    grid-column-start: 1;
    grid-column-end: 13;
    //grid-column-end: 4;
    //height: 0;
    border-bottom: 1px solid rgba(white, 0.1);
    padding-bottom: 0.8rem;
    margin-bottom: 1rem;

    @media ($tablet2) {
      height: auto;
      margin: 0;
      padding-bottom: 0.8rem;
      border-bottom: 1px solid rgba(white, 0.1);
    }
  }
}

.attention-description {
  font-family: "Advent Pro", sans-serif;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 1px;
  font-size: 0.8rem;
  line-height: 1rem;
  color: var(--darkgray);
  grid-column-start: 5;
  grid-column-end: 13;
  //grid-column-end: 4;
  //height: 0;
  border-bottom: 1px solid rgba(white, 0.1);
  padding-bottom: 0.8rem;
  margin-bottom: 1rem;

  @media ($tablet2) {
    grid-column-start: 1;
    grid-column-end: 13;
    height: auto;
    margin: 0;
    padding-bottom: 0.8rem;
    border-bottom: 1px solid rgba(white, 0.1);
  }
}

.map-container {
  grid-column-start: 1;
  grid-column-end: 5;
  background: red;
}

::v-deep .map-country {
  position: relative;
  padding: 0 0.2rem;
  transition: color 1s;
  cursor: pointer;
  border-radius: .2rem;

  //&:hover {
  //  animation: showTextArea .3s reverse forwards;
  //}

  &.show {
    animation: showTextArea 1.5s forwards;
  }

  //&:hover {
  //  animation: showTextArea .3s reverse forwards;
  //}
}

@keyframes showTextArea {
  0% {
    color: var(--dark);
    background: rgba(#EED98A, 1);
  }
  100% {
    color: var(--light-yellow);
    background: rgba(#EED98A, 0.1);
  }
}

@keyframes showBgArea {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}



//::v-deep [data-tooltip] {
//  position: relative;
//
//  &::after {
//    content: attr(data-tooltip);
//    position: absolute;
//    left: 50%;
//    top: calc(90% + 0.4rem);
//    transform: translateX(-50%);
//    width: 100%;
//    max-width: 10rem;
//    background: rgba(#A4A4A4, 0.8);
//    border: 1px solid rgba(#A4A4A4, 0.9);
//    color: white;
//    display: flex;
//    padding: .6rem;
//    border-radius: .4rem;
//    font-size: .6rem;
//    line-height: 1.3;
//    letter-spacing: 0;
//    font-weight: 500;
//    box-shadow: 0 20px 10px 0 rgba(0,0,0, .6);
//    backdrop-filter: blur(4px);
//    opacity: 0;
//    transition: opacity .3s, top .3s;
//    pointer-events: none;
//  }
//
//  &::before {
//    content: '';
//    position: absolute;
//    width: 1rem;
//    aspect-ratio: 1/1;
//    background: var(--darkgray);
//    left: 50%;
//    top: 90% ;
//    transform: translateX(-50%) rotate(45deg);
//    opacity: 0;
//    transition: opacity .3s, top .3s;
//    pointer-events: none;
//  }
//
//  &:hover {
//    &::after {
//      top: calc(100% + 0.4rem);
//      opacity: 1;
//      pointer-events: auto;
//    }
//
//    &::before {
//      top: 100% ;
//      opacity: 1;
//    }
//  }
//}
</style>
