<script>
import VideoP5 from "@/components/effects/videoP5.vue";
import {text} from "@/assets/js/data";

export default {
  name: "title-long-read",
  components: {VideoP5},
  props: {
    showPhoto: {
      type: Boolean,
      default: false,
    },
    showVideo: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Number,
      default: 8,
    }
  },
  data() {
    return {
      text: text,
      photosList: null,
      imgs: [
        '/img/slider/photo-1.webp',
        '/img/slider/photo-2.webp',
        '/img/slider/photo-3.webp',
        '/img/slider/photo-4.webp',
        '/img/slider/photo-6.webp',
        '/img/slider/photo-7.webp',
      ]
    }
  },
  watch: {
    showVideo(newVal, oldVal) {
      window.removeEventListener('mousemove', this.changeCoordinate);

      setTimeout(() => {
        document.body.classList.remove('overflow')
      }, 3000)

      const playPromise = this.$refs.video.play() || Promise.reject('');
      playPromise.then(() => {
      }).catch(err => {
        this.$refs.video.muted = true;
        this.$refs.video.play();
      });
    }
  },
  methods: {
    parallax() {
      window.addEventListener('mousemove', this.changeCoordinate)
    },
    changeCoordinate(e) {
      this.photosList.forEach((photo, index) => {
        let x = e.clientX / window.innerWidth;
        let y = e.clientY / window.innerHeight;

        const width = photo.getBoundingClientRect().width;
        const height = photo.getBoundingClientRect().height;
        photo.style.transform = 'translate(-' + x * (width / 70) + 'rem, -' + y * (height / 70) + 'rem)';
      })
    }
  },
  mounted() {
    this.photosList = this.$refs.photoContainer.childNodes;

    if (this.photosList) {
      this.parallax();
    }
  }
}
</script>

<template>
  <div class="title-wrapper" :style="{'--timeAnimation': `${time}s`}">
    <div class="photo-container" v-show="showPhoto" ref="photoContainer" :class="{'hidden-photo': showVideo}">
      <div class="photo" v-for="(photo, index) in imgs" :key="index + photo">
        <img :src="photo" alt="" class="show" rel="preload">
      </div>
    </div>

    <div class="video-container">
      <video src="/video/video.webm" ref="video" :class="{'full': showVideo}" autoplay="autoplay" loop muted
             playsinline="false" preload="auto" poster="/img/slider/photo-6.webp"></video>
    </div>

    <div class="text-container" v-if="showVideo">
      <div>
      <h1 class="title">АФРИКА И МИР</h1>
      <span class="text">ОСНОВНЫЕ ЭТАПЫ РАЗВИТИЯ</span>
      </div>
      <div class="chapters">
        <a class="chapter-item" v-for="(chapter, index) in text.parts" :key="index + chapter.title"
           :href="`#${chapter.id}`" :style="{'--bg': `url('${chapter.bg}')` }">
          <span class="chapter-index">{{ 'Часть ' + (index + 1) }}</span>
          <span class="chapter-title">
            {{ chapter.title }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/typography";
@import "~@/assets/style/utils/sizes";

.title-wrapper {
  //position: absolute;
  //left: 0;
  //right: 0;
  //top: 0;
  //bottom: 0;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

.photo {
  --width: 15rem;
  aspect-ratio: 3/2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 75%;
  width: var(--width);
  height: auto;
  margin-left: calc(var(--width) / -2);
  margin-top: calc(var(--height) / -2);
  overflow: hidden;
  background: var(--dark);

  @media ($tablet2) {
    aspect-ratio: 4/2;
  }

  &-container {
    opacity: 1;
    transition: 2s opacity 1s;

    &.hidden-photo {
      opacity: 0;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--dark);
    opacity: 0;
  }

  .show {
    opacity: 0;
    animation: var(--timeAnimation) show-photo 0s forwards;
  }

  img {
    object-fit: cover;
    --size: 120%;
    width: var(--size);
    height: var(--size);
  }

  &:first-child {
    --width: 12%;
    left: 17%;
    top: 28%;
    z-index: 1;

    img {
      transform: translateY(50%);
    }

    @media ($tablet2) {
      --width: 25%;
      filter: none;
      left: 20%;
      top: 20%;

      &:after {
        opacity: 0.4;
      }
    }
  }

  &:nth-child(2) {
    --width: 8.5%;
    filter: blur(2px);
    left: 83%;
    top: 27%;
    z-index: 1;


    &:after {
      opacity: 0.4;
    }

    img {
      transform: translateY(50%);
    }

    @media ($tablet2) {
      --width: 25%;
      filter: none;
      left: 80%;
      top: 20%;

      &:after {
        opacity: 0.4;
      }
    }
  }

  &:nth-child(3) {
    --width: 12%;
    left: 90%;
    top: 70%;
    z-index: -1;

    &:after {
      opacity: 0.2;
    }

    img {
      transform: translateY(20%);
    }

    @media ($tablet2) {
      --width: 25%;
      filter: none;
      left: 80%;
      top: 66%;

      &:after {
        opacity: 0.4;
      }
    }
  }

  &:nth-child(4) {
    --width: 13%;
    left: 53.5%;
    top: 74%;
    z-index: 1;

    &:after {
      opacity: 0.2;
    }

    img {
      transform: translateY(50%);
    }

    @media ($tablet2) {
      --width: 45%;
      left: 50%;
      top: 70%;
    }
  }

  &:nth-child(5) {
    --width: 8.5%;
    filter: blur(2px);
    left: 23%;
    top: 64%;
    z-index: -1;

    &:after {
      opacity: 0.4;
    }

    img {
      transform: translateY(50%);
    }

    @media ($tablet2) {
      --width: 25%;
      filter: none;
      left: 20%;
      top: 66%;

      &:after {
        opacity: 0.6;
      }
    }
  }

  &:last-child {
    --width: 19%;
    top: 13%;
    left: 55%;
    z-index: 1;

    img {
      transform: translateY(80%);
    }

    @media ($tablet2) {
      --width: 45%;
      top: 9%;
      left: 50%;
    }
  }

  &.show-video {
    --width: 100vw;
    --height: 100vh;
    top: 50%;
    left: 50%;
    transform: none !important;
    transition: 10s ease-in-out;
  }
}

@keyframes show-photo {
  100% {
    transform: translateY(0);
    opacity: 0.3;
  }
}

.video-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  max-width: 100vw;
  animation: var(--timeAnimation) show-video 0s forwards;
  overflow: hidden;

  video {
    width: 130%;
    transform: scale(0.4);
    height: auto;
    mask-image: radial-gradient(ellipse at center, black 30%, transparent 70%);
    filter: saturate(0);
    opacity: 0.06;
    transition: opacity 10s, transform 20s;
    z-index: -1;

    &.full {
      transform: scale(1);
      opacity: 0.1;
      //mask-image: radial-gradient(ellipse at center, black 70%, transparent 100%);
    }
  }
}

@keyframes show-video {
  100% {
    opacity: 1;
  }
}

.text-container {
  position: absolute;
  top: 0;
  //bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  //justify-content: space-around;
  height: 100vh;
  box-sizing: border-box;
  padding:  0 0 10%;

  margin: 2rem 0;

  @media ($tablet2) {
    margin: 3rem 0;
  }
}

.title {
  @include title;
  font-variation-settings: "wght" 900;
  font-size: 15.4vw;
  line-height: 1;
  white-space: nowrap;
  text-align: center;
  opacity: 0;

  background-image: url("/img/grunge_black.webp");
  background-clip: text;
  background-size: 20%;
  background-position: 0 0;
  color: transparent;
  background-color: var(--light);
  animation: 5s show-title 3s forwards;
  mask-image: linear-gradient(-45deg, transparent 20%, black 60%);
  mask-position: 150% 0;
  mask-size: 300% 110%;
  mask-repeat: no-repeat;

  @media ($tablet2) {
    font-size: 14.8vw;
  }
}

.text {
  display: block;
  padding: 0;
  font-variation-settings: "wght" 100;
  letter-spacing: 2.55vw;
  opacity: 0;
  font-size: 2vw;
  line-height: 1;
  white-space: nowrap;
  text-align: center;
  animation: 5s show-description 5s forwards;

  @media ($tablet2) {
    font-size: 1.8vw;
  }
}

@keyframes show-description {
  0% {
    opacity: 0;
    letter-spacing: 2.25vw;
  }
  100% {
    letter-spacing: 2.85vw;
    opacity: 1;
  }
}

@keyframes show-title {
  0% {
    opacity: 0;
    mask-position: 150% 0;
  }

  100% {
    opacity: 1;
    mask-position: 0 0;
  }
}

.chapters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 2rem;
  animation: 5s show-title 4s forwards;
  mask-image: linear-gradient(-45deg, transparent 20%, black 60%);
  mask-position: 150% 0;
  mask-size: 300% 110%;
  mask-repeat: no-repeat;

  @media ($tablet2) {
    grid-template-columns: repeat(1, 1fr);
    gap: 0.4rem;
    margin: 0 0.2rem;
  }
}

.chapter {
  &-item {
    position: relative;
    border-bottom: 1px solid rgba(white, .2);
    color: var(--light-yellow);
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 2rem 1rem 0;
    transition: padding 1s, border 1s;

    &::before {
      content: '';
      z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-image: var(--bg);
      background-size: 200%;
      background-repeat: no-repeat;
      background-position: 10% 20%;
      opacity: 0;
      transition: background-position 10s, opacity 2s;
    }

    &:hover {
      padding: 1rem ;
      border-color: rgba(white, 0);

      .chapter-title {
        color: var(--light-yellow);
      }

      &::before {
        opacity: 1;
        background-position: 0 20%;

      }
    }

    @media ($tablet2) {

    }
  }

  &-index {
    font-size: calc(0.4rem + 0.5vw);
    margin-bottom: .2rem;
    color: var(--darkgray);
  }

  &-title {
    @include title;
    color: var(--light);
    font-size: calc(0.6rem + 0.5vw);
    line-height: calc(0.6rem + 0.5vw);
    transition: color 1s;
  }
}


//.video-container {
//  z-index: 2;
//  position: absolute;
//  left: 0;
//  right: 0;
//  top: 0;
//  bottom: 0;
//  overflow: hidden;
//  background: black;
//  mix-blend-mode: screen;
//  filter: contrast(.85);
//  pointer-events: none;
//  //opacity: 0.2;
//
//  video {
//    width: 100%;
//    height: 100%;
//    object-fit: cover;
//    filter: saturate(0);
//    mix-blend-mode: multiply;
//  }
//}

@keyframes title {
  0% {
    background: white;
  }

  30% {
    background: transparent;
    top: 50%;
  }

  100% {
    top: 0;
    transform: translate(-50%, -1rem) scale(1);
  }
}
</style>
