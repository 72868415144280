import mouse from "!svg-sprite-loader!../../assets/icons/mouse.svg";

const ICONS = {
    mouse,
};

function camelCaseToDash(str) {
    return str.replace(/([a-zA-Z])(?=[A-Z])/g, "$1-").toLowerCase();
}

Object.keys(ICONS).forEach(key => {
    const dashKey = camelCaseToDash(key);
    ICONS[dashKey] = ICONS[key];
});

export default ICONS;
