<script>
import ScrollDownIcon from "@/components/scrollDownIcon.vue";

export default {
  name: "sequenceSlide",
  components: {ScrollDownIcon},
  props: {
    sequence: {
      type: Array,
      default: null,
    },
    isTragedy: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Number,
      default: 5,
    }
  },
  data() {
    return {
      isHidden: false,
      isShowMouse: false,
    }
  },
  methods: {
    hiddenSequence() {
      if(!this.isHidden) {
        this.$refs.sequence.classList.add('hidden');
        this.isShowMouse = false;
        this.$refs.sequence.removeEventListener('wheel', this.hiddenSequence);
        this.$refs.sequence.removeEventListener('touchmove', this.hiddenSequence);
        this.$emit('hidden-slide');
      }
    },
  },
  mounted() {
    this.$refs.sequence.addEventListener('wheel', this.hiddenSequence);
    this.$refs.sequence.addEventListener('touchmove', this.hiddenSequence);
    setTimeout(() => {
      this.isShowMouse = true;
    }, this.time * 1000)
  }
}

</script>

<template>
<div class="sequence-slide" :style="{'--timeAnimation': `${time}s`}" ref="sequence">
  <div class="container" >
    <div class="sequence__text" v-html="text" v-for="(text, index) in sequence" :key="text + index"></div>
  </div>
  <scroll-down-icon :time="time" :length-sequence="sequence.length" :is-show="isShowMouse"></scroll-down-icon>
</div>
</template>

<style scoped lang="scss">
@import '~@/assets/style/utils/sizes';

.sequence-slide {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  pointer-events: auto;
  color: var(--lightgray);
  z-index: 2;

  &.hidden {
    animation: calc(var(--timeAnimation) / 2) hidden-text forwards;

    ::v-deep .accent-text {
      animation: 2s show-accent-text forwards;
    }
  }

  .sequence__text {
    color: inherit;
    font-size: calc(1rem + 0.7vw);
    font-variation-settings: "wght" 300;
    line-height: calc((1rem + 0.7vw) * 1.4);
    letter-spacing: calc(0.02rem + 0.1vw);
    max-width: 1086px;
    mask-image: linear-gradient(-45deg, transparent 20%, black 60%);
    mask-position: 150% 0;
    mask-size: 300% 110%;
    mask-repeat: no-repeat;
    opacity: 1;
    transform: scale(1);
    filter: blur(0);
    pointer-events: none;

    &:first-child {
      animation: var(--timeAnimation) show-text forwards;
    }

    &:nth-child(2) {
      animation: var(--timeAnimation) show-text calc(var(--timeAnimation) / 2) forwards;
    }

    & + .sequence__text {
      margin-top: 2rem;
    }

    @media ($tablet2) {
      hyphens: auto;
    }

    ::v-deep .accent-text {
      background-image: url('/img/blood_texture.webp');
      background-size: 100%;
      background-position: 50% 50%;
      font-variation-settings: "wght" 700;
      color: var(--light-yellow);
      transition: color 0.3s;
    }
  }
}

@keyframes show-text {
  0% {
    opacity: 0;
    transform: scale(.8);
    filter: blur(30px);
  }

  40% {
    filter: blur(0);
  }

  50% {
    filter: blur(0);
    transform: scale(1);
  }

  100% {
    mask-position: 0 0;
    opacity: 1;
    transform: scale(1);
    filter: blur(0);
  }
}

@keyframes show-accent-text {

  100% {
    background-size: 115%;
  }
}

@keyframes hidden-text {
  40% {
    color: transparent;
  }

  50% {
    color: transparent;
    opacity: 1;
  }

  100% {
    transform: translateY(-6%);
    opacity: 0;
    color: transparent;
  }
}
</style>
