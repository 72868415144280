<template>
  <div id="app" lang="ru">
    <cover :isLoading="isLoading" v-if="isShowCover" @hidden-cover="hiddenCover"></cover>
    <sequence-block v-if="isShowSequence"></sequence-block>
    <template v-if="isShowText">
      <header-component @toggleSidebar="toggleSidebar()" ref="header"></header-component>
      <part-block :part="part" v-for="(part, index) in text.parts" :key="part + index" :indexPart="index"></part-block>
      <projects class="projects-main"></projects>
      <to-top-btn></to-top-btn>
    </template>

    <sidebar :class="{'is-active': isActiveSidebar}" @toggleSidebar="toggleSidebar()"></sidebar>
  </div>
</template>

<script>
import SlideCredits from "@/components/slideCredits.vue";
import Icon from "@/components/Icon/Icon.vue";
import {text} from "@/assets/js/data";
import SequenceBlock from "@/components/sequence/sequenceBlock.vue";
import PartBlock from "@/components/text/partBlock.vue";
import ToTopBtn from "@/components/toTopBtn.vue";
import Cover from "@/components/cover.vue";
import TestMap from "@/components/testMap.vue";
import HeaderComponent from "@/components/header.vue";
import Sidebar from "@/components/sidebar.vue";
import Projects from "@/components/projects.vue";

export default {
  name: 'App',
  components: {
    Projects,
    Sidebar,
    HeaderComponent,
    TestMap,
    Cover,
    ToTopBtn,
    PartBlock,
    SequenceBlock,
    Icon,
    SlideCredits
  },
  data() {
    return {
      text: text,
      isLoading: true,
      isShowCover: true,
      isShowSequence: false,
      isShowText: false,
      isActiveSidebar: false,
    }
  },
  methods: {
    bodyOverflowToggle(isScroll = false) {
      isScroll ? document.body.classList.remove('overflow') : document.body.classList.add('overflow')
    },
    hiddenCover() {
      this.isShowSequence = true
      this.isShowText = true
      setTimeout(() => {
        this.isShowCover = false
      }, 3000)
    },
    toggleSidebar() {
      document.body.classList.toggle('overflow');
      this.$refs.header.$el.querySelector('.header__menu').classList.toggle('is-active');
      this.isActiveSidebar = !this.isActiveSidebar
    }
  },
  mounted() {
    document.body.querySelector('#preloader').classList.add('animate__fadeOut');
    setTimeout(() => {
      this.isLoading = false;
    }, 3000)

    this.bodyOverflowToggle();
  }
}
</script>

<style lang="scss">
@import "assets/style/base";

#app {
  min-height: 100vh;
  width: 100%;
  background-image: url("/img/texture_2.webp"), url("/img/texture_1.webp");
  background-size: 30%, 30%;
  background-position: top left, top right;
  background-repeat: repeat-y, repeat-y;

  @media (max-width: 1024px) {
    background-size: 70%, 70%;
  }
}

a {
  color: white;
}

.projects-main {
  margin: 1rem 1.5rem !important;
}
</style>
